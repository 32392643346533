var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])}),_c('v-card',{staticClass:"pa-5 mt-5",attrs:{"elevation":"2"}},[_c('v-card-title',[_vm._v(" 見積申請一覧 "),_c('div',{staticClass:"ml-auto",staticStyle:{"max-width":"200px"}},[_c('v-select',{staticClass:"ml-auto",attrs:{"width":"200","items":_vm.filterStatusArray,"item-text":"text","item-value":"value","label":"ステータスを選択してください。","dense":"","outlined":""},on:{"change":_vm.filterTableByStatus},model:{value:(_vm.filterStatus),callback:function ($$v) {_vm.filterStatus=$$v},expression:"filterStatus"}})],1)]),_c('v-card-text',[_c('v-data-table',{staticClass:"row-pointer",attrs:{"show-select":"","headers":_vm.headers,"items":_vm.users,"options":_vm.options,"server-items-length":_vm.totalUsers,"loading":_vm.loading,"footer-props":{
          'items-per-page-options': [10, 25, 50, 100]
        },"items-per-page":50},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.openQuoteDetail},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-btn',{staticClass:"pa-3",attrs:{"text":"","color":"error"},on:{"click":_vm.deleteMultipleItem}},[_vm._v("複数削除")])]},proxy:true},{key:"item.location_name",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.location_name + ', ' + item.city))])]}},{key:"item.remark",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"line-clamp-truncate"},[_vm._v(" "+_vm._s(item.remark)+" ")])]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.date",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.date)+" ")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }