<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card elevation="2" class="pa-5 mt-5">
      <v-card-title>
        見積申請一覧
        <div class="ml-auto" style="max-width: 200px">
          <v-select
            v-model="filterStatus"
            class="ml-auto"
            width="200"
            :items="filterStatusArray"
            item-text="text"
            item-value="value"
            label="ステータスを選択してください。"
            dense
            outlined
            @change="filterTableByStatus"
          ></v-select>
        </div>
      </v-card-title>

      <v-card-text>
        <v-data-table
          v-model="selected"
          show-select
          :headers="headers"
          :items="users"
          :options.sync="options"
          :server-items-length="totalUsers"
          :loading="loading"
          class="row-pointer"
          @click:row="openQuoteDetail"
          :footer-props="{
            'items-per-page-options': [10, 25, 50, 100]
          }"
          :items-per-page="50"
        >
          <template v-slot:top>
            <v-btn text @click="deleteMultipleItem" color="error" class="pa-3"
              >複数削除</v-btn
            >
          </template>
          <template v-slot:item.location_name="{ item }">
            <span class="text-no-wrap">{{
              item.location_name + ', ' + item.city
            }}</span>
          </template>
          <template v-slot:item.remark="{ item }">
            <span class="line-clamp-truncate">
              {{ item.remark }}
            </span>
          </template>
          <template v-slot:item.status="{ item }">
            <span class="text-no-wrap">
              {{ item.status }}
            </span>
          </template>
          <template v-slot:item.date="{ item }">
            <span class="text-no-wrap">
              {{ item.date }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import api from '@/services/api-methods'

export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: '見積管理',
          disabled: false,
          to: { name: 'Quote' },
          exact: true
        },
        {
          text: '見積申請一覧',
          disabled: true,
          to: { name: '' },
          exact: true
        }
      ],
      filterStatus: '',
      filterStatusArray: [
        { text: '全て', value: 'all' },
        { text: '見積り1', value: '見積り1' },
        { text: 'メール', value: 'メール' },
        { text: '見積り2', value: '見積り2' },
        { text: '相談/問い合せ', value: '相談/問い合せ' },
        { text: '対応完了', value: '対応完了' }
      ],
      totalUsers: 0,
      users: [],
      selected: [],
      loading: true,
      options: {
        sortBy: ['id'],
        sortDesc: ['true'],
        condtion_key: [''],
        condtion_key_value: ['']
      },
      headers: [
        {
          text: 'ID',
          align: 'start',
          // sortable: false,
          value: 'id'
        },
        { text: '申請日', value: 'date' },
        { text: 'ステータス', value: 'status' },
        // { text: 'IPアドレス', value: 'ip_address' },
        { text: '場所', value: 'location_name' },
        { text: 'その他', value: 'remark' }
      ]
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true
    }
  },
  computed: {
    ...mapState(['alert']),
    reqParams() {
      return {
        itemsPerPage: this.options.itemsPerPage,
        page: this.options.page,
        sortBy: this.options.sortBy[0],
        sortDesc: this.options.sortDesc[0],
        condtion_key: this.options.condtion_key[0],
        condtion_key_value: this.options.condtion_key_value[0]
      }
    }
  },
  methods: {
    filterTableByStatus() {
      if (this.filterStatus == 'all') {
        this.options.condtion_key = ['']
        this.options.condtion_key_value = ['']
      } else {
        this.options.condtion_key = ['status']
        this.options.condtion_key_value = [`${this.filterStatus}`]
      }
      console.log(this.filterStatus)
    },
    getDataFromApi() {
      this.loading = true
      api
        .get('/quotations', this.reqParams)
        .then(data => {
          this.users = data.data.data
          this.totalUsers = data.total
          this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    openQuoteDetail(row) {
      this.$router.push({ name: 'QuoteView', params: { id: row.id } })
    },
    deleteMultipleItem() {
      if (this.selected.length <= 0) {
        this.$swal.fire(
          'アクションなし！',
          'チェックボックスを選択してください！',
          'error'
        )
        return false
      }
      this.$swal
        .fire({
          title: 'この操作を実行してもよろしいですか？',
          text: '実行すると元に戻せません。',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '削除する',
          cancelButtonText: 'キャンセル'
        })

        .then(result => {
          if (result.isConfirmed) {
            this.loading = true
            api
              .post('/quotations/delete-multiple', this.selected)
              .then(response => {
                if (response.data.status == 'success') {
                  this.$swal.fire('', '見積もりは削除されました。', 'success')
                  this.selected = []
                  this.getDataFromApi()
                }
              })
              .catch(err => {
                console.log(err)
                this.loading = false
              })
          }
        })
    }
  }
}
</script>

<style lang="css" scoped>
.row-pointer > tbody tr :hover {
  cursor: pointer;
}
.line-clamp-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 100%;
}
</style>
